<template>
    <div class="homework_stu">
        <div class="ls_flexalign_center search_part">
            <el-select v-model="params.status" size="medium">
                <el-option v-for="item in statusOptions" :key="item.label" :label="item.label" :value="item.value" />
            </el-select>
            <el-input v-model.trim="params.competitionName" size="medium" placeholder="请输入比赛名称"></el-input>
            <el-button size="medium" plain @click="(params.pageIndex = 1), getList()">
                查询
            </el-button>
        </div>
        <el-table :data="list" highlight-current-row :header-cell-style="{ background: '#FAFAFA' }" style="width: 100%">
            <el-table-column label="比赛名称" min-width="180">
                <template slot-scope="scope">
                    <span :title="scope.row.competitionName" class="written">
                        {{ scope.row.competitionName }}
                    </span>
                </template>
            </el-table-column>
            <el-table-column prop="competitionStartTime" label="比赛开始时间"></el-table-column>
            <el-table-column prop="competitionEndTime" label="比赛结束时间"></el-table-column>
            <el-table-column label="状态">
                <template slot-scope="scope">
                    <span :class="{ main_color: scope.row.status !== 1 }">
                        {{ statusVal(scope.row.status) }}
                    </span>
                </template>
            </el-table-column>
            <el-table-column label="分数">
                <template slot-scope="scope">
                    {{ scope.row.score || "-" }}
                </template>
            </el-table-column>
            <el-table-column label="排名">
                <template slot-scope="scope">
                    {{ scope.row.rank || "-" }}
                </template>
            </el-table-column>
            <el-table-column label="操作" width="220px">
                <template slot-scope="scope">
                    <div class="opt_part">
                        <span v-if="scope.row.status == 1" @click="gowork(scope.row)">
                            参加比赛
                        </span>
                        <span v-if="scope.row.status == 2 || scope.row.status == 3
                            " @click="gowork(scope.row)">
                            查看作品
                        </span>
                    </div>
                </template>
            </el-table-column>
        </el-table>
        <div style="text-align: center; margin-top: 15px">
            <el-pagination :current-page.sync="params.pageIndex" :page-size.sync="params.pageSize" :total="total" background layout="total,prev, pager, next" @current-change="getList" />
        </div>
    </div>
</template>

<script>
export default {
    data () {
        return {
            statusOptions: [
                { label: "全部", value: "" },
                { label: "未参加", value: 1 },
                { label: "已参加", value: 2 },
            ],
            params: {
                pageSize: 10,
                pageIndex: 1,
                status: "",
                competitionName: "",
            },
            total: 0,
            list: [],
        };
    },
    created () {
        this.getList();
    },
    methods: {
        gowork (row) {
            if (row.status == 1) {
                // 参加比赛
                window.open(this.$editAddr + "?type=competition&mode=edit&id=" + row.id);
            } else if (row.status == 2 || row.status == 3) {
                window.open(this.$editAddr + "?type=competition&mode=examine&id=" + row.id);
            }
        },
        getList () {
            this.$ajax
                .post("/competition/student/queryList", this.params)
                .then(res => {
                    this.list = res.list;
                    this.total = res.total;
                });
        },
        statusVal (type) {
            var txt = "";
            switch (type) {
                case 1:
                    txt = "未参加";
                    break;
                case 2:
                    txt = "评分中";
                    break;
                case 3:
                    txt = "已参加";
                    break;
                case 4:
                    txt = "已超时";
                    break;
                default:
                    txt = "未知";
            }
            return txt;
        },
    },
};
</script>

<style lang="scss" scoped>
.homework_stu {
    padding: 30px 30px 0 0;
}

.search_part {
    margin-bottom: 15px;

    >* {
        +* {
            margin-left: 7px;
        }

        &:first-child {
            width: 120px;
        }

        &:nth-child(2) {
            width: 200px;
        }
    }
}
</style>
